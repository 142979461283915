import React from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <div>
      <h1>Home</h1>
      <Link to="/admin">
        <button>Admin Page</button>
      </Link>
      <Link to="/recipient">
        <button>Recipient Page</button>
      </Link>
      <Link to="/validator">
        <button>Validator Page</button>
      </Link>
    </div>
  );
};